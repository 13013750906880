import { KeyedMutator } from 'swr'
import { t, Trans } from '@lingui/macro'
import { usePost } from '@src/Fetch/helpers'
import { getOrderRootId, isOrderDayOld } from '@src/Order/helpers'
import {
  CmaOrder,
  CsbOrder,
  CsbRestaurantInfo,
  NaiveDateTime,
} from '@kjt01/greendot-wasm'
import { useState } from 'react'
import { useSnackbarContext } from '@src/Snackbar'
import Button from '@src/Button'
import { formatTimeLeft } from '@src/Customer/helpers'
import CustomerMobileButton from '@src/CustomerMobile/CustomerMobileButton'

const CustomerAskSKT = ({
  order,
  updateOrder,
  restaurant,
  isSendingOrderReadyMessage,
  timeLeft,
  lastCustomerNotifiedTime,
  lastAskSKTTriggeredTime,
}: {
  order: CsbOrder
  updateOrder: KeyedMutator<CmaOrder[]>
  restaurant: CsbRestaurantInfo
  isSendingOrderReadyMessage: boolean
  timeLeft: number | null
  lastCustomerNotifiedTime: NaiveDateTime | null
  lastAskSKTTriggeredTime: NaiveDateTime | null
}) => {
  const poster = usePost()
  const { onSuccess, onError } = useSnackbarContext()

  const [isSubmitting, setIsSubmitting] = useState(false)

  if (
    order?.order_metadata?.order_status === 'voided' ||
    isOrderDayOld({ order, restaurant })
  ) {
    return null
  }

  const isWebView = 'ReactNativeWebView' in window

  const ButtonComponent = isWebView ? CustomerMobileButton : Button

  return (
    <ButtonComponent
      isDisabled={
        isSubmitting ||
        isSendingOrderReadyMessage ||
        lastCustomerNotifiedTime == null ||
        (timeLeft != null && formatTimeLeft(timeLeft) !== '00:00') ||
        lastAskSKTTriggeredTime != null
      }
      isSubmitting={isSubmitting}
      onClick={async () => {
        setIsSubmitting(true)
        await poster(
          'ask_skt',
          { order_root_id: getOrderRootId(order) },
          [],
          async () => {
            await updateOrder()
            onSuccess(t`SKT has been asked to call.`)
            setIsSubmitting(false)
          },
          () => {
            onError(t`Failed to ask SKT to call. Please try again.`)
            setIsSubmitting(false)
          },
        )
      }}
    >
      {lastAskSKTTriggeredTime != null ? (
        <Trans>SKT Notified to Call</Trans>
      ) : lastCustomerNotifiedTime == null ? (
        <Trans>Ask SKT to Call (Message First)</Trans>
      ) : timeLeft != null && formatTimeLeft(timeLeft) !== '00:00' ? (
        <Trans>Ask SKT to Call ({formatTimeLeft(timeLeft)})</Trans>
      ) : (
        <Trans>Ask SKT to Call</Trans>
      )}
    </ButtonComponent>
  )
}

export default CustomerAskSKT

import { useState } from 'react'
import { Trans, t } from '@lingui/macro'
import {
  CmaOrder,
  CsbOrder,
  CsbRestaurantInfo,
  NaiveDateTime,
} from '@kjt01/greendot-wasm'
import { usePost } from '@src/Fetch/helpers'
import Modal from '@src/Modal'
import { useSnackbarContext } from '@src/Snackbar'
import { KeyedMutator } from 'swr'
import { getOrderRootId, isOrderDayOld } from '@src/Order/helpers'
import { colors, constants, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import Button from '@src/Button'
import { formatTimeLeft, getRestaurantNumber } from '@src/Customer/helpers'
import MobileDrawer from '@src/MobileDrawer'
import CustomerMobileButton from '@src/CustomerMobile/CustomerMobileButton'

const CustomerSendMessage = ({
  order,
  updateOrder,
  restaurant,
  phoneNumbers,
  timeLeft,
  lastCustomerNotifiedTime,
  isSubmitting,
  setIsSubmitting,
}: {
  order: CsbOrder
  updateOrder: KeyedMutator<CmaOrder[]>
  restaurant: CsbRestaurantInfo
  phoneNumbers: { label: string; phone: string | null }[]
  timeLeft: number | null
  lastCustomerNotifiedTime: NaiveDateTime | null
  isSubmitting: boolean
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const isWebView = 'ReactNativeWebView' in window

  const poster = usePost()
  const { onSuccess, onError } = useSnackbarContext()
  const { typography } = useTypographyContext()

  const [isOpen, setIsOpen] = useState(false)
  const [currentPhone, setCurrentPhone] = useState('')

  const restaurantNumber = getRestaurantNumber(restaurant)

  const sendMessage = (phone: string) => {
    setIsSubmitting(true)
    poster(
      'send_customer_notification',
      {
        phone_numbers: [phone],
        reason: { kind: 'order_ready' },
        content:
          order.service_type === 'pickup'
            ? // eslint-disable-next-line lingui/no-unlocalized-strings
              `${restaurant.parent_enterprise.name_complex.english_name} 

Your order is ready for pickup. Have questions? Call ${restaurantNumber}. See you soon!` //formatted to add white space between restaurant name and message
            : // eslint-disable-next-line lingui/no-unlocalized-strings
              `${restaurant.parent_enterprise.name_complex.english_name} 
              
Your delivery has arrived, please come outside. Have questions? Call ${restaurantNumber}.`, //formatted to add white space between restaurant name and message
        order_root_id: getOrderRootId(order),
      },
      [],
      async () => {
        await updateOrder()
        setTimeout(() => {
          onSuccess(t`Message sent`)
          setIsSubmitting(false)
          setIsOpen(false)
        }, 1000)
      },
      () => {
        setIsSubmitting(false)
        onError(t`Something went wrong. Please try again.`)
      },
    )
  }

  const ButtonComponent = isWebView ? CustomerMobileButton : Button

  return (
    <>
      <ButtonComponent
        isDisabled={
          (timeLeft != null && formatTimeLeft(timeLeft) !== '00:00') ||
          order?.order_metadata?.order_status === 'voided' ||
          isOrderDayOld({ order, restaurant }) ||
          isSubmitting
        }
        isSubmitting={isSubmitting}
        onClick={() => {
          if (phoneNumbers.length === 0) {
            onError(t`Cannot send message due to missing customer phone number`)
            return
          } else if (restaurantNumber == null) {
            onError(t`Cannot send message due to missing restaurant number`)
            return
          } else if (
            phoneNumbers.length === 1 &&
            phoneNumbers[0].phone != null
          ) {
            sendMessage(phoneNumbers[0].phone)
          } else {
            setIsOpen(true)
          }
        }}
      >
        {timeLeft != null && formatTimeLeft(timeLeft) !== '00:00' ? (
          <>
            <Trans>Resend Message</Trans>
            <div css={{ width: 75 }}>({formatTimeLeft(timeLeft)})</div>
          </>
        ) : lastCustomerNotifiedTime != null ? (
          <Trans>Resend Order Ready Message</Trans>
        ) : (
          <Trans>Send Order Ready Message</Trans>
        )}
      </ButtonComponent>
      {isWebView ? (
        <MobileDrawer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          content={
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: spacing.base,
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  background: colors.white[500],
                  borderRadius: constants.borderRadius.medium,
                }}
              >
                {phoneNumbers.map(({ label, phone }) =>
                  phone != null ? (
                    <CustomerMobileButton
                      key={label}
                      onClick={() => {
                        setCurrentPhone(phone)

                        sendMessage(phone)
                      }}
                      isSubmitting={isSubmitting && currentPhone === phone}
                      isDisabled={isSubmitting && currentPhone !== phone}
                    >
                      {phone}
                    </CustomerMobileButton>
                  ) : null,
                )}
              </div>
              <Button
                isDisabled={isSubmitting}
                onClick={() => setIsOpen(false)}
                cssProps={{ border: 'none' }}
              >
                <Trans>Close</Trans>
              </Button>
            </div>
          }
        />
      ) : (
        <Modal
          header={<Trans>Send Order Ready Message</Trans>}
          isDisabled={false}
          isOpen={isOpen}
          onClose={() => {
            setIsOpen(false)
            setIsSubmitting(false)
          }}
          onCancel={() => {
            setIsOpen(false)
            setIsSubmitting(false)
          }}
          cancelLabel={t`Cancel`}
          isSubmitting={isSubmitting}
          content={
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: spacing.normal,
              }}
            >
              {phoneNumbers.map(({ label, phone }) =>
                phone != null ? (
                  <div key={label}>
                    <div
                      css={{
                        paddingBottom: spacing.small,
                        ...typography.label.large,
                      }}
                    >
                      {label}:
                    </div>
                    <Button
                      isSubmitting={isSubmitting && phone === currentPhone}
                      isDisabled={isSubmitting && phone !== currentPhone}
                      onClick={() => {
                        setCurrentPhone(phone)
                        sendMessage(phone)
                      }}
                      cssProps={{ width: 300, maxWidth: '100%' }}
                    >
                      {phone}
                    </Button>
                  </div>
                ) : null,
              )}
            </div>
          }
        />
      )}
    </>
  )
}

export default CustomerSendMessage

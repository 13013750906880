export const formatPhone = (phone: string | null | undefined) => {
  if (!phone) return null

  const cleaned = ('' + phone).replace(/\+\d{1}/, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }
  return null
}

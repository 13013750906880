import Drawer from '@mui/material/Drawer'
import { constants, spacing } from '@src/Styles'

const MobileDrawer = ({
  isOpen,
  onClose,
  content,
}: {
  isOpen: boolean
  onClose: () => void
  content: React.ReactNode
}) => {
  return (
    <Drawer
      anchor={'bottom'}
      open={isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          background: 'none',
          boxShadow: 'none',
          margin: `${spacing.moderate}px`,
          borderRadius: `${constants.borderRadius.medium}px`,
        },
      }}
    >
      {content}
    </Drawer>
  )
}

export default MobileDrawer

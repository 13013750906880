import Button from '@src/Button'
import { colors, constants } from '@src/Styles'

const CustomerMobileButton = ({
  children,
  onClick,
  isDisabled,
  isSubmitting,
}: {
  children: React.ReactNode
  onClick?: () => void
  isDisabled?: boolean
  isSubmitting?: boolean
}) => {
  return (
    <Button
      isDisabled={isDisabled}
      isSubmitting={isSubmitting}
      cssProps={{
        border: 'none',
        borderRadius: 0,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        borderBottom: `1px solid ${colors.neutrals[100]}`,
        // @ts-ignore
        ':nth-of-type(1)': {
          borderTopRightRadius: constants.borderRadius.medium,
          borderTopLeftRadius: constants.borderRadius.medium,
        },
        ':last-of-type': {
          borderBottomRightRadius: constants.borderRadius.medium,
          borderBottomLeftRadius: constants.borderRadius.medium,
          borderBottom: 'none',
        },
        ':disabled': {
          background: colors.neutrals[25],
          borderColor: colors.neutrals[100],
          color: colors.neutrals[100],
          cursor: 'not-allowed',
        },
      }}
      onClick={onClick}
    >
      {children}
    </Button>
  )
}

export default CustomerMobileButton

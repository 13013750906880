import {
  Dialog,
  useTheme,
  DialogProps,
  useMediaQuery,
  CircularProgress,
} from '@mui/material'
import Button from '@src/Button'
import { Trans } from '@lingui/macro'
import { colors, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'

export const BUTTON_WIDTH = 100

const Modal = ({
  header,
  subheader,
  content,
  isOpen,
  isSubmitting,
  isDisabled,
  onClose,
  onCancel,
  cancelLabel,
  cancelProps,
  onConfirm,
  confirmLabel,
  onSecondaryConfirm,
  secondaryConfirmLabel,
  dialogProps,
}: {
  header: React.ReactNode
  subheader?: React.ReactNode
  content?: React.ReactNode
  isOpen: boolean
  isSubmitting: boolean
  isDisabled: boolean
  onClose: () => void
  onCancel?: () => void
  cancelLabel?: React.ReactNode
  cancelProps?: { variant: 'primary' | 'blue' | 'gray' }
  onConfirm?: () => void
  confirmLabel?: React.ReactNode
  onSecondaryConfirm?: () => void
  secondaryConfirmLabel?: React.ReactNode
  dialogProps?: DialogProps['PaperProps']
}) => {
  const { typography } = useTypographyContext()
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Dialog
      PaperProps={{
        sx: {
          margin: 0,
          minWidth: 300,
          width: isMobileView ? 300 : 450,
          ...dialogProps,
        },
      }}
      open={isOpen}
      onClose={(_, reason) => {
        if (reason === 'backdropClick' && !isSubmitting) {
          onClose()
        }
      }}
    >
      <div css={{ padding: spacing.normal }}>
        {(header != null || subheader != null) && (
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: spacing.base,
              paddingBottom: spacing.comfy,
            }}
          >
            <div css={{ ...typography.title.large }}>{header}</div>
            {subheader != null && (
              <div
                css={{
                  ...typography.title.small,
                  color: colors.neutrals[300],
                }}
              >
                {subheader}
              </div>
            )}
          </div>
        )}
        {content != null && (
          <div css={{ paddingBottom: spacing.comfy }}>{content}</div>
        )}
        <div
          css={{
            display: 'flex',
            gap: spacing.normal,
            ...(isMobileView
              ? { width: '100%', flexDirection: 'column-reverse' }
              : { justifyContent: 'flex-end' }),
          }}
        >
          {onCancel != null && (
            <Button
              isDisabled={isSubmitting}
              onClick={() => {
                if (isSubmitting) return
                onClose()
                onCancel()
              }}
              size="small"
              variant={
                cancelProps?.variant ?? (onConfirm != null ? 'primary' : 'blue')
              }
              cssProps={{ width: 'unset', minWidth: 100 }}
            >
              {cancelLabel != null ? cancelLabel : <Trans>Cancel</Trans>}
            </Button>
          )}
          {onConfirm != null && (
            <Button
              isDisabled={isDisabled || isSubmitting}
              variant="blue"
              size="small"
              onClick={() => {
                if (isDisabled || isSubmitting) return
                onConfirm()
              }}
              cssProps={{ width: 'unset', minWidth: 100 }}
            >
              {isSubmitting ? (
                <CircularProgress sx={{ color: colors.white[500] }} size={20} />
              ) : confirmLabel != null ? (
                confirmLabel
              ) : (
                <Trans>Submit</Trans>
              )}
            </Button>
          )}
          {onSecondaryConfirm != null && (
            <Button
              isDisabled={isDisabled || isSubmitting}
              variant="blue"
              size="small"
              onClick={() => {
                if (isDisabled || isSubmitting) return
                onSecondaryConfirm()
              }}
              cssProps={{ width: 'unset', minWidth: 100 }}
            >
              {isSubmitting ? (
                <CircularProgress sx={{ color: colors.white[500] }} size={20} />
              ) : secondaryConfirmLabel != null ? (
                secondaryConfirmLabel
              ) : (
                <Trans>Proceed</Trans>
              )}
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  )
}

export default Modal

import {
  CsbOrder,
  CsbRestaurantInfo,
  NaiveDateTime,
  WstructIdentityComplex,
} from '@kjt01/greendot-wasm'
import { t } from '@lingui/macro'
import { getTimeZoneDateTime } from '@src/DateTime/helpers'
import { formatPhone } from '@src/Help/helpers'
import { getName } from '@src/Text/helpers'
import dayjs from 'dayjs'
import { objectEntries } from 'ts-extras'

export const getCustomerPhoneNumber = (
  customer: WstructIdentityComplex | null,
) => {
  if (customer == null) return null

  if ('phone_complex' in customer) {
    return formatPhone(customer?.phone_complex?.phone_number)
  }

  return null
}

export const getNotificationExpiration = (
  time: NaiveDateTime | null | undefined,
  timeZone: string | undefined,
) => {
  if (time == null) return null

  const localTime = getTimeZoneDateTime({
    timestamp: null,
    timeZone,
  })

  const expiration = dayjs(localTime).diff(dayjs(time).add(10, 'minutes'), 'ms')

  return expiration
}

export const formatTimeLeft = (time: number) => {
  const minutes = Math.floor((-time / 1000 / 60) % 60)
    .toFixed(0)
    .padStart(2, '0')
  const seconds = Math.floor((-time / 1000) % 60)
    .toFixed(0)
    .padStart(2, '0')

  return `${minutes}:${seconds}`
}

export const getCustomerPhoneNumbers = (order: CsbOrder) => {
  const { phone_number, contact_phone_number } =
    order.customer_idn_complex.phone_complex || {}

  const customerPhoneNumbers = [
    {
      label: t`Customer Phone Number`,
      phone: formatPhone(phone_number),
    },
    ...(contact_phone_number != null
      ? [
          {
            label: t`Alternative Phone Number`,
            phone: formatPhone(contact_phone_number),
          },
        ]
      : []),
  ]

  return customerPhoneNumbers
}

export const getRestaurantNumber = (restaurant: CsbRestaurantInfo) => {
  const phoneNumbers = [...(restaurant.location_phone_numbers ?? [])]
    ?.sort(
      (a, b) =>
        a.phone_number_props.row_priority - b.phone_number_props.row_priority,
    )
    .filter(
      (entry) => entry.phone_number_props.phone_number_type === 'business',
    )
    .reduce((acc, phoneNumber) => {
      return {
        ...acc,
        [getName(phoneNumber)]: formatPhone(
          phoneNumber.phone_number_props.phone_number,
        ),
      }
    }, {})

  const restaurantNumber =
    phoneNumbers != null && objectEntries(phoneNumbers).length > 0
      ? objectEntries(phoneNumbers)[0][1]
      : null

  return restaurantNumber
}

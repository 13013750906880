import {
  CmaOrder,
  CsbOrder,
  CsbRestaurantInfo,
  NaiveDateTime,
} from '@kjt01/greendot-wasm'
import { Trans } from '@lingui/macro'
import Button from '@src/Button'
import CustomerMobileCall from '@src/CustomerMobile/CustomerMobileCall'
import {
  getCustomerPhoneNumbers,
  getNotificationExpiration,
} from '@src/Customer/helpers'
import { colors, constants, spacing } from '@src/Styles'
import MobileDrawer from '@src/MobileDrawer'
import { useEffect, useState } from 'react'
import { KeyedMutator } from 'swr'
import CustomerAskSKT from '@src/Customer/CustomerAskSKT'
import CustomerSendMessage from '@src/Customer/CustomerSendMessage'

const CustomerMobile = ({
  isOpen,
  setIsOpen,
  restaurant,
  order,
  updateOrder,
  lastCustomerNotifiedTime,
  lastAskSKTTriggeredTime,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  restaurant: CsbRestaurantInfo
  order: CsbOrder
  updateOrder: KeyedMutator<CmaOrder[]>
  lastCustomerNotifiedTime: NaiveDateTime | null
  lastAskSKTTriggeredTime: NaiveDateTime | null
}) => {
  const customerPhoneNumbers = getCustomerPhoneNumbers(order)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const expiration = getNotificationExpiration(
    lastCustomerNotifiedTime,
    restaurant?.location?.time_zone ?? undefined,
  )
  const [timeLeft, setTimeLeft] = useState<number | null>(expiration)

  useEffect(() => {
    if (expiration == null || expiration > 0) {
      setTimeLeft(null)
      return
    }

    const interval = setInterval(() => {
      const expiration = getNotificationExpiration(
        lastCustomerNotifiedTime,
        restaurant?.location?.time_zone ?? undefined,
      )
      setTimeLeft(expiration)
    }, 1000)

    return () => clearInterval(interval)
  }, [expiration, lastCustomerNotifiedTime, restaurant?.location?.time_zone])

  return (
    <MobileDrawer
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      content={
        <div
          css={{ display: 'flex', flexDirection: 'column', gap: spacing.base }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'column',
              background: colors.white[500],
              borderRadius: constants.borderRadius.medium,
            }}
          >
            <CustomerMobileCall customerPhoneNumbers={customerPhoneNumbers} />
            <CustomerSendMessage
              order={order}
              updateOrder={updateOrder}
              restaurant={restaurant}
              phoneNumbers={customerPhoneNumbers}
              timeLeft={timeLeft}
              lastCustomerNotifiedTime={lastCustomerNotifiedTime}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />

            <CustomerAskSKT
              order={order}
              updateOrder={updateOrder}
              restaurant={restaurant}
              timeLeft={timeLeft}
              isSendingOrderReadyMessage={isSubmitting}
              lastCustomerNotifiedTime={lastCustomerNotifiedTime}
              lastAskSKTTriggeredTime={lastAskSKTTriggeredTime}
            />
          </div>

          <Button
            isDisabled={isSubmitting}
            onClick={() => setIsOpen(false)}
            cssProps={{ border: 'none' }}
          >
            <Trans>Close</Trans>
          </Button>
        </div>
      }
    />
  )
}

export default CustomerMobile

import { Trans } from '@lingui/macro'
import Button from '@src/Button'
import CustomerMobileButton from '@src/CustomerMobile/CustomerMobileButton'
import MobileDrawer from '@src/MobileDrawer'
import { colors, constants, spacing } from '@src/Styles'
import { useState } from 'react'

const CustomerMobileCall = ({
  customerPhoneNumbers,
}: {
  customerPhoneNumbers: {
    label: string
    phone: string | null
  }[]
}) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {customerPhoneNumbers.length > 1 ? (
        <CustomerMobileButton onClick={() => setIsOpen(true)}>
          <Trans>Call Customer</Trans>
        </CustomerMobileButton>
      ) : (
        <Button
          cssProps={{
            borderRadius: constants.borderRadius.medium,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            border: 'none',
            // eslint-disable-next-line lingui/no-unlocalized-strings
            borderBottom: `1px solid ${colors.neutrals[100]}`,
          }}
          onClick={() =>
            window.open(`tel:+1${customerPhoneNumbers[0].phone}`, '_self')
          }
        >
          <Trans>Call Customer</Trans>
        </Button>
      )}
      {customerPhoneNumbers.length > 1 ? (
        <MobileDrawer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          content={
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: spacing.base,
              }}
            >
              <div
                css={{
                  display: 'flex',
                  flexDirection: 'column',
                  background: colors.white[500],
                  borderRadius: constants.borderRadius.medium,
                }}
              >
                {customerPhoneNumbers.map(({ label, phone }) =>
                  phone != null ? (
                    <a
                      key={label}
                      href={`tel:+1${phone}`}
                      css={{
                        textDecoration: 'none',
                        ':nth-of-type(1)': {
                          button: {
                            borderTopRightRadius: constants.borderRadius.medium,
                            borderTopLeftRadius: constants.borderRadius.medium,
                          },
                        },
                        ':not(:nth-last-of-type(-n+1))': {
                          button: {
                            // eslint-disable-next-line lingui/no-unlocalized-strings
                            borderBottom: `1px solid ${colors.neutrals[100]}`,
                          },
                        },
                        ':nth-last-of-type(-n + 1)': {
                          button: {
                            borderBottomRightRadius:
                              constants.borderRadius.medium,
                            borderBottomLeftRadius:
                              constants.borderRadius.medium,
                          },
                        },
                      }}
                    >
                      <Button
                        cssProps={{
                          border: 'none',
                          borderRadius: 0,
                        }}
                      >
                        {phone}
                      </Button>
                    </a>
                  ) : null,
                )}
              </div>
              <Button
                onClick={() => setIsOpen(false)}
                cssProps={{ border: 'none' }}
              >
                <Trans>Cancel</Trans>
              </Button>
            </div>
          }
        />
      ) : null}
    </>
  )
}

export default CustomerMobileCall

import { CsbOrder, CsbRestaurantInfo, Wid } from '@kjt01/greendot-wasm'
import Link from 'next/link'
import { Trans } from '@lingui/macro'
import Button from '@src/Button'
import Customer from '@src/Customer'
import CustomerMobile from '@src/CustomerMobile'
import { useWonders } from '@src/Fetch/helpers'
import {
  getOrderRootId,
  getOrderRootWid,
  getOrderVersion,
} from '@src/Order/helpers'
import { useRouter } from 'next/router'
import { useState } from 'react'

export const environment =
  process.env.NEXT_PUBLIC_IMAGE_TAG === 'undefined' ||
  process.env.NEXT_PUBLIC_IMAGE_TAG?.includes('dev')
    ? 'development'
    : process.env.NEXT_PUBLIC_IMAGE_TAG?.includes('qa')
      ? 'qa'
      : 'production'

const OrderCustomer = ({
  restaurant,
  order,
}: {
  restaurant: CsbRestaurantInfo
  order: CsbOrder
}) => {
  const router = useRouter()

  const { wid, vid } = router.query as {
    wid: Wid | string
    vid: Wid | string
  }

  const shouldLoadOrder = wid != null && wid !== '' && vid != null && vid !== ''

  const { data: orders, mutate: updateOrders } = useWonders(
    shouldLoadOrder
      ? [
          `get_orders_by_root`,
          {
            order_root_id: getOrderRootId(order),
          },
        ]
      : null,
  )

  const { last_customer_notified_time, last_ask_skt_triggered_time } =
    orders?.[orders.length - 1] ?? {}

  const isWebView = 'ReactNativeWebView' in window

  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {order.service_type === 'delivery' && environment !== 'production' && (
        <Link
          href={`/orders/${order.ticket_number}/delivery-info?wid=${getOrderRootWid(order)}&vid=${getOrderVersion(order)}`}
          // eslint-disable-next-line lingui/no-unlocalized-strings
          css={{ textDecoration: 'none', gridColumn: 'span 2' }}
        >
          <Button>
            <Trans>Delivery Info</Trans>
          </Button>
        </Link>
      )}

      <Button isDisabled={false} onClick={() => setIsOpen(true)}>
        <Trans>Contact Customer</Trans>
      </Button>

      {isWebView ? (
        <CustomerMobile
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          restaurant={restaurant}
          order={order}
          updateOrder={updateOrders}
          lastCustomerNotifiedTime={last_customer_notified_time ?? null}
          lastAskSKTTriggeredTime={last_ask_skt_triggered_time ?? null}
        />
      ) : (
        <Customer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          restaurant={restaurant}
          order={order}
          updateOrder={updateOrders}
          lastCustomerNotifiedTime={last_customer_notified_time ?? null}
          lastAskSKTTriggeredTime={last_ask_skt_triggered_time ?? null}
        />
      )}
    </>
  )
}

export default OrderCustomer

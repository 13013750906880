import {
  CmaOrder,
  CsbOrder,
  CsbRestaurantInfo,
  NaiveDateTime,
} from '@kjt01/greendot-wasm'
import { t } from '@lingui/macro'
import CustomerSendMessage from '@src/Customer/CustomerSendMessage'
import {
  getCustomerPhoneNumbers,
  getNotificationExpiration,
} from '@src/Customer/helpers'
import Modal from '@src/Modal'
import { colors, spacing } from '@src/Styles'
import { useTypographyContext } from '@src/Typography'
import { useEffect, useState } from 'react'
import { KeyedMutator } from 'swr'
import CustomerAskSKT from '@src/Customer/CustomerAskSKT'

const Customer = ({
  isOpen,
  setIsOpen,
  restaurant,
  order,
  updateOrder,
  lastCustomerNotifiedTime,
  lastAskSKTTriggeredTime,
}: {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  restaurant: CsbRestaurantInfo
  order: CsbOrder
  updateOrder: KeyedMutator<CmaOrder[]>
  lastCustomerNotifiedTime: NaiveDateTime | null
  lastAskSKTTriggeredTime: NaiveDateTime | null
}) => {
  const { typography } = useTypographyContext()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const customerPhoneNumbers = getCustomerPhoneNumbers(order)

  const expiration = getNotificationExpiration(
    lastCustomerNotifiedTime,
    restaurant?.location?.time_zone ?? undefined,
  )
  const [timeLeft, setTimeLeft] = useState<number | null>(expiration)

  useEffect(() => {
    if (expiration == null || expiration > 0) {
      setTimeLeft(null)
      return
    }

    const interval = setInterval(() => {
      const expiration = getNotificationExpiration(
        lastCustomerNotifiedTime,
        restaurant?.location?.time_zone ?? undefined,
      )
      setTimeLeft(expiration)
    }, 1000)

    return () => clearInterval(interval)
  }, [expiration, lastCustomerNotifiedTime, restaurant?.location?.time_zone])

  return (
    <Modal
      header={t`Customer Information`}
      content={
        <div
          css={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div
            css={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: spacing.normal,
            }}
          >
            {customerPhoneNumbers.map(({ label, phone }) =>
              phone != null ? (
                <div key={label}>
                  {customerPhoneNumbers.length > 1 ? (
                    <div
                      css={{
                        paddingBottom: spacing.normal,
                        ...typography.label.large,
                      }}
                    >
                      {label}:
                    </div>
                  ) : null}
                  <a
                    href={`tel:+1${phone}`}
                    css={{
                      display: 'block',
                      width: 'max-content',
                      ...typography.title.large,
                      textDecoration: 'none',
                      color: colors.neutrals[500],
                    }}
                  >
                    {phone}
                  </a>
                </div>
              ) : null,
            )}
            <div
              css={{
                display: 'grid',
                gridTemplateColumns:
                  'repeat(auto-fill, minmax(min(100%, 320px), 1fr))',
                gap: spacing.normal,
                width: '100%',
              }}
            >
              <CustomerSendMessage
                timeLeft={timeLeft}
                lastCustomerNotifiedTime={lastCustomerNotifiedTime}
                restaurant={restaurant}
                order={order}
                updateOrder={updateOrder}
                phoneNumbers={customerPhoneNumbers}
                isSubmitting={isSubmitting}
                setIsSubmitting={setIsSubmitting}
              />

              <CustomerAskSKT
                timeLeft={timeLeft}
                order={order}
                updateOrder={updateOrder}
                restaurant={restaurant}
                isSendingOrderReadyMessage={isSubmitting}
                lastCustomerNotifiedTime={lastCustomerNotifiedTime}
                lastAskSKTTriggeredTime={lastAskSKTTriggeredTime}
              />
            </div>
          </div>
        </div>
      }
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      isDisabled={false}
      onClose={() => setIsOpen(false)}
      onCancel={() => setIsOpen(false)}
      cancelLabel={t`Close`}
    />
  )
}

export default Customer
